import LazyPageI from '@classes/lazyPage/types.ts';

const loadCrmJoins: LazyPageI['loadComponent'] = async function (name) {
    let Component;

    if (name === 'joins-invites') {
        Component = (await import('../../../../views/crm/joins/components/invites/Invites'))
            .default;
    }

    if (name === 'joins-scripts') {
        Component = (await import('../../../../views/crm/joins/components/scripts/Scripts'))
            .default;
    }

    if (name === 'joins-templates') {
        Component = (await import('../../../../views/crm/joins/components/templates/Templates'))
            .default;
    }

    if (name === 'joins-contracts') {
        Component = (await import('../../../../views/crm/joins/components/contracts/Contracts'))
            .default;
    }

    if (name === 'joins-mvd') {
        Component = (await import('../../../../views/crm/joins/components/mvd/Mvd')).default;
    }

    if (name === 'joins-docs') {
        Component = (await import('../../../../components/crm/modelsPage/ModelsPage')).default;
    }

    return Component;
};

export default loadCrmJoins;
