const icons = [
    'success',
    'download',
    'eye',
    'info',
    'close',
    'close-circle',
    'edit',
    'plus',
    'arrow-prev',
    'done',
    'done-thin',
    'done-2',
    'done-3',
    'arrow-next',
    'arrow-next-short',
    'arrow-next-2',
    'arrow-next-3',
    'arrow-prev-short',
    'arrow-prev-2',
    'filter',
    'search',
    'delete',
    'add',
    'calendar',
    'forget-password-complete',
    'chat',
    'chat-send',
    'chat-voice',
    'chat-attach',
    'chat-attach-app',
    'chat-file',
    'chat-delete-file',
    'chat-done',
    'chat-done-read',
    'chat-templates',
    'chat-read',
    'file-type-doc',
    'file-type-image',
    'file-upload',
    'alert',
    'alert-2',
    'reload',
    'reload-2',
    'sideBar-content',
    'sideBar-settings',
    'sideBar-chat',
    'sideBar-admin',
    'admin',
    'sideBar-pays',
    'sideBar-manual',
    'sideBar-joins',
    'sideBar-corporations',
    'sideBar-recruit',
    'sideBar-tasks',
    'widget-filter',
    'more-horizontal',
    'more-vertical',
    'actions-edit',
    'actions-delete',
    'actions-photo',
    'actions-exit',
    'actions-repeat',
    'actions-logs',
    'actions-logs-dark',
    'actions-choice',
    'actions-add',
    'actions-remove',
    'actions-download',
    'actions-responsible',
    'actions-targetBlank',
    'actions-copyLink',
    'actions-open',
    'actions-checkError',
    'actions-withdraw',
    'actions-pin',
    'chat-doc-add',
    'widget-user',
    'widget-export',
    'chat-messages',
    'chat-sms',
    'chat-invite',
    'chat-next',
    'chat-doc-upload',
    'chat-search',
    'chat-close',
    'accept',
    'arrow-back',
    'blog-text-edit-bold',
    'edit-3',
    'blog-text-edit-italic',
    'blog-text-edit-link',
    'blog-text-edit-color',
    'blog-image',
    'blog-delete',
    'blog-move',
    'blog-arrow-up',
    'audio-play',
    'audio-pause',
    'bar-notifications',
    'copy',
    'copy-for-btn',
    'copy-2',
    'pin',
    'app-info-user',
    'app-info-corporation',
    'app-main-map',
    'app-main-orders',
    'app-main-market',
    'app-main-chat',
    'app-main-balance',
    'app-main-settings',
    'app-main-pays',
    'app-main-notification',
    'app-main-corporations',
    'app-touch',
    'app-settings-profile',
    'app-settings-legal',
    'app-settings-wallet',
    'app-settings-fns-tax',
    'app-order-complete',
    'app-order-cancel',
    'app-order-wait',
    'app-order-market',
    'app-order-completed-light',
    'app-order-cancel-light',
    'app-order-fast',
    'app-order-home',
    'app-order-call',
    'app-order-chat',
    'app-order-camera',
    'app-done',
    'app-change-order-add',
    'app-change-order-delete',
    'app-change-order-change',
    'app-change-order-cancel',
    'app-change-order-cancel-crew',
    'app-change-order-complete',
    'app-order-comment',
    'app-change-order-coords',
    'app-geo',
    'app-order-cursor',
    'app-accept-get',
    'app-network-disconnect',
    'app-instruction-apple',
    'app-instruction-android',
    'app-instruction-upload',
    'app-instruction-menu',
    'app-new-version',
    'file-pdf',
    'file-excel',
    'instruction-file',
    'emodzi',
    'rotate',
    'telegram',
    'executor-chat',
    'pays-sign',
    'pays-status',
    'editor-bold',
    'editor-italic',
    'editor-color',
    'editor-uppercase',
    'editor-underline',
    'editor-indent',
    'editor-ol',
    'editor-ul',
    'editor-alignLeft',
    'editor-alignCenter',
    'editor-alignRight',
    'editor-alignJustify',
    'editor-link',
    'editor-image',
    'editor-doc',
    'editor-table',
    'editor-gap',
    'editor-ancor',
    'editor-quote',
    'editor-clear',
    'editor-title',
    'join-telegram-arrow',
    'fns',
    'info-more',
    'public-about-1',
    'public-about-2',
    'public-about-3',
    'public-about-4',
    'public-about-5',
    'public-about-6',
    'public-about-7',
    'public-about-8',
    'public-about-9',
    'public-plus',
    'app-download-apple',
    'app-download-google',
    'app-download-rustore',
    'app-download-appgalery',
    'public-menu',
    'public-close',
    'app-logo',
    'noExecutorSign-act',
    'role-chat',
    'role-joins',
    'role-pays',
    'role-orders',
    'role-reports',
    'role-manual',
    'role-content',
    'role-settings',
    'role-corporations',
    'role-recruit',
    'role-tasks',
    'app-wallet-card',
    'app-wallet-account',
    'pep-success',
    'pep-error',
    'pep-date',
    'pep-user',
    'pep-corporation',
    'corporation-history',
    'corporation-balance',
    'edit-2',
    'corporation-limit',
    'corporation-verify',
    'corporation-settings-download',
    'corporation-settings-delete',
    'corporation-settings-search',
    'corporation-tariff-service-order',
    'corporation-tariff-system-order',
    'corporation-tariff-pay',
    'corporation-limits-period',
    'link',
    'mailing-play',
    'mailing-pause',
    'mailing-completed',
    'blog-view',
    'blog-time',
    'article-announce',
    'public-business-presentation',
    'public-call-button',
    'public-popup-close',
    'calendar-prev',
    'calendar-next',
    'popup-close',
    'filter-delete',
    'popup-alert',
    'new-version',
    'colors',
    'add-trigger',
    'drag',
    'pin-2',
    'deal-trigger-task',
    'deal-trigger-sms',
    'deal-trigger-join',
    'notifications',
] as const;

export default icons;
