import React from 'react';

import CorporationInfo from '@components/crm/manual/CorporationInfo.jsx';
import setSpacesInText from '@functions/setSpacesInText.ts';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { notification } = this.props;
    const { corporationInfo } = notification;
    const notificationInfo = this.notifications[notification.type];
    const title =
        typeof notificationInfo.title === 'string'
            ? notificationInfo.title
            : notificationInfo.title.call(this);
    const description =
        typeof notificationInfo.description === 'string'
            ? notificationInfo.description
            : notificationInfo.description.call(this);

    return (
        <>
            <div
                className="v2notification__title"
                dangerouslySetInnerHTML={{ __html: setSpacesInText(title) }}
            ></div>
            <div className="v2notification__description">
                <span
                    className="v2notification__descriptionInner"
                    dangerouslySetInnerHTML={{ __html: setSpacesInText(description) }}
                ></span>
                {corporationInfo && corporationInfo.length > 0 && (
                    <div className="v2notification__descriptionCorporation">
                        <CorporationInfo key={corporationInfo[0].name} {...corporationInfo[0]} />
                    </div>
                )}
            </div>
        </>
    );
};

export default renderContent;
