import I from '../types.ts';

const loop: I['loop'] = function () {
    const { white } = this.props;
    const canvas = this.canvas.current!;
    const ctx = canvas.getContext('2d')!;
    let i = this.flakes.length;
    const color = white ? 255 : 0;

    if (this.animateId) {
        cancelAnimationFrame(this.animateId);
    }

    const size = this.getParentSize();

    ctx.save();
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, size.width, size.height);
    ctx.restore();

    while (i--) {
        const flake = this.flakes[i];

        this.updateFlake(i);

        ctx.beginPath();
        ctx.arc(flake.x, flake.y, flake.weight, 0, 2 * Math.PI, false);
        ctx.fillStyle = `rgba(${color}, ${color}, ${color}, ` + flake.alpha + ')';
        ctx.fill();

        if (flake.y >= size.height) {
            flake.y = -flake.weight;
        }
    }

    this.animateId = requestAnimationFrame(loop.bind(this));
};

export default loop;
