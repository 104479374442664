import getFormatPrice from '@functions/getFormatPrice.ts';
import setAnimate from '@functions/setAnimate.ts';

import I from '../types.ts';

import { BlockT } from '../static/blocks.tsx';

const progressHandler: I['progressHandler'] = function () {
    const { currentBlock } = this.state;

    this.parent.current!.querySelectorAll('.newYearPopup__navStep').forEach((step) => {
        const stepNode = step.querySelector('.newYearPopup__navStepInner') as HTMLElement;

        stepNode.style.width = '';
    });

    if (this.navStepAnimateId) {
        cancelAnimationFrame(this.navStepAnimateId);
    }

    if (this.countAnimateId) {
        cancelAnimationFrame(this.countAnimateId);
    }

    if (currentBlock !== 'start' && currentBlock !== 'result') {
        const block = this.blocks[currentBlock] as BlockT;
        const currentNavStep = this.parent.current!.querySelector(
            `.newYearPopup__navStep._current .newYearPopup__navStepInner`,
        ) as HTMLElement;
        const currentCount = this.parent.current!.querySelector(
            `.newYearPopup__block._current .newYearPopup__statsCount span`,
        ) as HTMLElement;

        // currentCount.innerHTML = getFormatPrice(block.count);

        // currentCount.style.width = `${currentCount.offsetWidth}px`;

        setAnimate({
            timing: (t) => t,
            duration: 1_000,
            draw: (progress) => {
                const resultCount =
                    Math.round(Math.round(progress * block.count!) / block.round!) * block.round!;

                let text = getFormatPrice(resultCount);

                if (block.end) {
                    text += block.end;
                }

                currentCount.innerHTML = text;
            },
            getId: (id) => {
                this.countAnimateId = id;
            },
            callback: () => {
                this.setState({ wasResult: false });
            },
        });

        setAnimate({
            timing: (t) => t,
            duration: 7_000,
            draw: (progress) => {
                currentNavStep.style.width = `${progress * 100}%`;
            },
            getId: (id) => {
                this.navStepAnimateId = id;
            },
            callback: () => {
                this.setCurrentBlock('next');
            },
        });
    }
};

export default progressHandler;
