import AlertI from '../../types';

const newVersion = {
    icon: 'new-version',
    title(this: AlertI) {
        const { info } = this.state;

        return `Вышла новая версия ${info}`;
    },
    description:
        'В CRM были внесены изменения, влияющие на работу всей системы. <br />Во избежание сбоев рекомендуется обновить страницу.',
    buttonText: 'Обновить страницу',
    cancelText: 'Напомнить позже',
} as const;

export default newVersion;
