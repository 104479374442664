import React from 'react';

import I from '../types.ts';

import Announce from '../../announce/Announce.tsx';

const renderItem: I['renderItem'] = function ({ item }) {
    const { showAnnounce } = this.props;

    return (
        <>
            <Announce announce={item} showAnnounce={showAnnounce} />
        </>
    );
};

export default renderItem;
