import React from 'react';

import I from '../types.ts';

const renderDecor: I['renderDecor'] = function () {
    const { currentBlock, wasResult } = this.state;

    return (
        <>
            <img
                src={require('../../../../img/crm/newYear/popup-decor.svg').default}
                alt=""
                className={`newYearPopup__topDecor newYearPopup__decor ${currentBlock !== 'result' ? '_show' : ''}`}
            />
            <img
                src={require('../../../../img/crm/newYear/popup-start-left-back.svg').default}
                alt=""
                className={`newYearPopup__startBack _left newYearPopup__decor ${!['start', 'result'].includes(currentBlock) ? '_show' : ''}`}
            />
            <img
                src={require('../../../../img/crm/newYear/popup-start-right-back.svg').default}
                alt=""
                className={`newYearPopup__startBack _right newYearPopup__decor ${!['start', 'result'].includes(currentBlock) ? '_show' : ''}`}
            />
            <img
                src={require('../../../../img/crm/newYear/popup-snow.svg').default}
                alt=""
                className={`newYearPopup__snow newYearPopup__decor ${['start', 'result'].includes(currentBlock) ? '_show' : ''}`}
            />
            {this.resultDecors.map((resultDecor) => (
                <div
                    className={`newYearPopup__resultDecor _${resultDecor} newYearPopup__decor ${currentBlock === 'result' ? '_show' : ''}`}
                    key={resultDecor}
                >
                    <img
                        src={require(
                            `../../../../img/crm/newYear/popup-result-fair-${resultDecor}.svg`,
                        )}
                        alt=""
                        className={`newYearPopup__resultDecor _image _${resultDecor} ${wasResult ? '_animate' : ''}`}
                    />
                </div>
            ))}
        </>
    );
};

export default renderDecor;
