import setAsyncState from '@functions/setAsyncState.ts';
import getListItems from '@requests/getListItems.ts';

import I from '../types.ts';

const getCounters: I['getCounters'] = async function () {
    const { notificationsQuery = [] } = this.state;
    const { user } = this.props;

    if (!user) {
        return;
    }

    const query = [{ key: 'getCounters', value: 'true' }, ...notificationsQuery];
    const { notificationsCounter, announcesCounter } = await getListItems<
        {},
        { notificationsCounter: number; announcesCounter: number }
    >({
        url: 'notifications',
        query,
    });

    await setAsyncState.call(this, { notificationsCounter, announcesCounter });
};

export default getCounters;
