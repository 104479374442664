import I from '../types.ts';

const updateFlake: I['updateFlake'] = function (key) {
    const flake = this.flakes[key];

    if (flake) {
        flake.x += Math.cos(flake.a) * flake.r;
        flake.a += flake.aStep;

        flake.y += flake.speed;
    }
};

export default updateFlake;
