import React from 'react';

import I from '../types.ts';

import Notification from '../../notification/Notification.tsx';

const renderItem: I['renderItem'] = function ({ item }) {
    return (
        <>
            <Notification notification={item} />
        </>
    );
};

export default renderItem;
