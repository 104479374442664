import I from '../types.ts';

const moveHandler: I['moveHandler'] = function (e) {
    const cursor = this.parent.current!;

    this.x = e.pageX;
    this.y = e.pageY;

    cursor.style.transform = `translate(${this.x - 7}px,${this.y - 3}px)`;

    const hoverElemsCount =
        document.querySelectorAll('._CLICK:hover').length +
        document.querySelectorAll('._click:hover').length;

    if (!this.state.isPointer && hoverElemsCount) {
        this.setState({ isPointer: true });
    }

    if (this.state.isPointer && !hoverElemsCount) {
        this.setState({ isPointer: false });
    }
};

export default moveHandler;
