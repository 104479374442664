import changeBeelineToken from './contents/changeBeelineToken.ts';
import changeDocsDeniedCauseCorpVerify from './contents/changeDocsDeniedCauseCorpVerify.ts';
import changeExecutorDeniedCauseCorpVerify from './contents/changeExecutorDeniedCauseCorpVerify.ts';
import changeExecutorDeniedCauseInvite from './contents/changeExecutorDeniedCauseInvite.ts';
import changeExecutorOrg from './contents/changeExecutorOrg.ts';
import changeExecutorOrgDeniedCausePays from './contents/changeExecutorOrgDeniedCausePays.ts';
import changeExecutorOrgDeniedCauseTax from './contents/changeExecutorOrgDeniedCauseTax.ts';
import changePassportDeniedCauseCorpVerify from './contents/changePassportDeniedCauseCorpVerify.ts';
import chatExecutorDocExists from './contents/chatExecutorDocExists.ts';
import chatResponsible from './contents/chatResponsible.ts';
import chatSupportMessage from './contents/chatSupportMessage.ts';
import checkCalcPayAmount from './contents/checkCalcPayAmount.ts';
import contractAlreadyCreate from './contents/contractAlreadyCreate.ts';
import contractHasMvd from './contents/contractHasMvd.ts';
import dealStepSupport from './contents/dealStepSupport.ts';
import deleteArticle from './contents/deleteArticle.ts';
import deleteBeelineUser from './contents/deleteBeelineUser.ts';
import deleteChatMailing from './contents/deleteChatMailing.ts';
import deleteCity from './contents/deleteCity.ts';
import deleteDealGroup from './contents/deleteDealGroup.ts';
import deleteDealGroupDenied from './contents/deleteDealGroupDenied.ts';
import deleteExecutor from './contents/deleteExecutor.ts';
import deleteExecutorDenied from './contents/deleteExecutorDenied.ts';
import deleteIntegration from './contents/deleteIntegration.ts';
import deleteJoin from './contents/deleteJoin.ts';
import deleteJoinDoc from './contents/deleteJoinDoc.ts';
import deleteJoinDocDenied from './contents/deleteJoinDocDenied.ts';
import deleteJoinScript from './contents/deleteJoinScript.ts';
import deleteJoinScriptDenied from './contents/deleteJoinScriptDenied.ts';
import deletePayGroup from './contents/deletePayGroup.ts';
import deletePayGroupDenied from './contents/deletePayGroupDenied.ts';
import deletePayProject from './contents/deletePayProject.ts';
import deletePayProjectDenied from './contents/deletePayProjectDenied.ts';
import deletePays from './contents/deletePays.ts';
import deleteTag from './contents/deleteTag.ts';
import deleteTask from './contents/deleteTask.ts';
import deleteTaskDenied from './contents/deleteTaskDenied.ts';
import deleteUser from './contents/deleteUser.ts';
import deleteWallet from './contents/deleteWallet.ts';
import deletetCountry from './contents/deletetCountry.ts';
import deletetExecutorType from './contents/deletetExecutorType.ts';
import deletetExecutorTypeDenied from './contents/deletetExecutorTypeDenied.ts';
import executorActive from './contents/executorActive.tsx';
import inactiveJoinContract from './contents/inactiveJoinContract.ts';
import inactiveJoinContractHasMvd from './contents/inactiveJoinContractHasMvd.ts';
import inviteExecutor from './contents/inviteExecutor.ts';
import inviteUser from './contents/inviteUser.ts';
import listDealGroup from './contents/listDealGroup.tsx';
import newVersion from './contents/newVersion.ts';
import parseAct from './contents/parseAct.tsx';
import rejectVerification from './contents/rejectVerification.tsx';
import removeTax from './contents/removeTax.ts';
import roleUsersHasProxies from './contents/roleUsersHasProxies.ts';
import serverNotAvailable from './contents/serverNotAvailable.ts';
import setCardNumberFromChat from './contents/setCardNumberFromChat.ts';
import stopPays from './contents/stopPays.ts';
import stopPaysEmpty from './contents/stopPaysEmpty.ts';
import userRemoveProxy from './contents/userRemoveProxy.ts';
import verification from './contents/verification.ts';
import withdrawTax from './contents/withdrawTax.ts';

const contents = {
    deleteJoin,
    deleteUser,
    deletePays,
    deletePayGroupDenied,
    deletePayGroup,
    inactiveJoinContractHasMvd,
    inactiveJoinContract,
    removeTax,
    withdrawTax,
    parseAct,
    deleteTag,
    contractAlreadyCreate,
    contractHasMvd,
    deletePayProjectDenied,
    deletePayProject,
    checkCalcPayAmount,
    inviteUser,
    userRemoveProxy,
    changePassportDeniedCauseCorpVerify,
    rejectVerification,
    changeDocsDeniedCauseCorpVerify,
    roleUsersHasProxies,
    deletetCountry,
    deleteCity,
    deleteJoinDoc,
    deleteJoinDocDenied,
    deletetExecutorType,
    deletetExecutorTypeDenied,
    deleteJoinScript,
    deleteJoinScriptDenied,
    changeExecutorDeniedCauseCorpVerify,
    changeExecutorOrgDeniedCausePays,
    changeExecutorOrg,
    deleteWallet,
    executorActive,
    inviteExecutor,
    changeExecutorDeniedCauseInvite,
    deleteExecutor,
    deleteExecutorDenied,
    changeExecutorOrgDeniedCauseTax,
    stopPays,
    stopPaysEmpty,
    chatSupportMessage,
    chatResponsible,
    setCardNumberFromChat,
    deleteChatMailing,
    verification,
    serverNotAvailable,
    newVersion,
    chatExecutorDocExists,
    deleteIntegration,
    deleteBeelineUser,
    changeBeelineToken,
    dealStepSupport,
    deleteDealGroup,
    deleteDealGroupDenied,
    deleteTask,
    deleteTaskDenied,
    deleteArticle,
    listDealGroup,
} as const;

export default contents;
