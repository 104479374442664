import MobPopupI from '../types.ts';

const getInner: MobPopupI['getInner'] = function () {
    if (!this.parent.current) {
        return null;
    }

    return (this.parent.current as HTMLElement).querySelector('.v2popup__mob');
};

export default getInner;
