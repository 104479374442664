import React from 'react';

import Icon from '@components/icon/Icon.tsx';

import I from '../types.ts';

const renderBlocks: I['renderBlocks'] = function () {
    const { currentBlock } = this.state;
    const navSteps = this.blocksOrder.filter((name) => name !== 'start' && name !== 'result');

    return (
        <>
            <div className="newYearPopup__blocks">
                {this.blocksOrder.map((name, key) => {
                    const block = this.blocks[name];
                    const isCurrent = currentBlock === name;
                    const currentKey = this.blocksOrder.indexOf(currentBlock);
                    const blockState = isCurrent ? '' : key < currentKey ? '_prev' : '_next';

                    return (
                        <div
                            className={`newYearPopup__block _COL _${name} ${isCurrent ? '_current' : ''} ${blockState}`}
                            key={name}
                        >
                            {block.render.call(this)}
                        </div>
                    );
                })}
            </div>
            <div
                className={`newYearPopup__nav _ROW ${!['start', 'result'].includes(currentBlock) ? '_show' : ''}`}
            >
                <div
                    className="newYearPopup__navArrow _COL _CLICK _prev"
                    onClick={this.setCurrentBlock.bind(this, 'prev')}
                >
                    <i className="newYearPopup__navArrowIcon">
                        <Icon name="arrow-prev-2" />
                    </i>
                </div>
                <div className="newYearPopup__navSteps _ROW">
                    {navSteps.map((step) => (
                        <div
                            className={`newYearPopup__navStep _CLICK ${currentBlock === step ? '_current' : ''}`}
                            key={step}
                            data-key={step}
                            onClick={this.setCurrentBlock.bind(this, step)}
                        >
                            <div className="newYearPopup__navStepInner"></div>
                        </div>
                    ))}
                </div>
                <div
                    className="newYearPopup__navArrow _COL _CLICK _next"
                    onClick={this.setCurrentBlock.bind(this, 'next')}
                >
                    <i className="newYearPopup__navArrowIcon">
                        <Icon name="arrow-next-2" />
                    </i>
                </div>
            </div>
        </>
    );
};

export default renderBlocks;
