import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import LazyImage from '@components/lazyImage/lazyImage.tsx';
import Switch from '@components/switch/Switch.tsx';
import setSpacesInText from '@functions/setSpacesInText.ts';
import { deleteCursorSupport, setCursorSupport } from '@functions/supportHandler.ts';
import ArticleT from '@global/models/Article.ts';

import { RenderColsT } from '../../types.ts';

const renderContentArticles: RenderColsT<'contentBlog'> = function ({ name: colName, item }) {
    const article = item as ArticleT;
    const { loadings } = this.state;

    let content;
    let className;

    if (colName === 'preview') {
        const { otherData } = this.props;

        className = '_full';

        if (article.type === 'announce' || article.type === 'legal' || article.preview.fullSrc) {
            className += ' _withContent';
        }

        const setActive = otherData?.setActive as (data: {
            article: ArticleT;
            publicateType: ArticleT['publicateType'];
        }) => Promise<void>;
        const isActive = article.publicateType !== 'future';
        const supportId = `disabled-${article._id}`;
        const supportProps = setCursorSupport(
            {
                _id: supportId,
                content: isActive ? 'Скрыть статью' : 'Показать статью',
                dir: 'bottom',
            },
            { targetClassName: '.v2table__switch', className: '' },
        );

        content = (
            <>
                <div
                    className="v2table__switch _abs"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    {...supportProps}
                >
                    <Switch
                        className="_mediumSize _green"
                        value={isActive}
                        name="isActive"
                        disabled={!!loadings[article._id]}
                        onChange={async () => {
                            await this.loadingHandler({ name: article._id, loading: true });

                            try {
                                await setActive({
                                    article,
                                    publicateType:
                                        article.publicateType === 'future' ? 'now' : 'future',
                                });
                            } catch (error) {}

                            deleteCursorSupport({ id: supportId });

                            await this.loadingHandler({ name: article._id, loading: undefined });
                        }}
                    />
                </div>
                {article.type === 'announce' ? (
                    <>
                        <div className="v2table__image _article _mainBack _COL">
                            <div className="v2table__imageIcon">
                                <Icon name="article-announce" />
                            </div>
                        </div>
                    </>
                ) : article.type === 'legal' ? (
                    <>
                        <div className="v2table__image _article _mainBack _COL">
                            <div className="v2table__imageIcon">
                                <Icon name="file-type-doc" />
                            </div>
                        </div>
                    </>
                ) : article.preview.fullSrc ? (
                    <>
                        <div className="v2table__image _article">
                            <LazyImage src={article.preview.fullSrc} cover={true} />
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </>
        );
    }

    if (colName === 'title') {
        content = (
            <>
                <div
                    className="v2table__text"
                    dangerouslySetInnerHTML={{ __html: setSpacesInText(article.title) }}
                ></div>
            </>
        );
    }

    if (colName === 'section') {
        content = (
            <>
                <div className="v2table__text">{article.sectionText}</div>
            </>
        );
    }

    if (colName === 'system') {
        content = (
            <>
                <div className="v2table__text">{article.systemText}</div>
            </>
        );
    }

    return { content, className };
};

export default renderContentArticles;
