import LazyPage from '@classes/lazyPage/LazyPage';

import AppI from '../types';

const Index = process.env.REACT_APP_SEO === 'true' && require('../../index/Index').default;
const Corporations =
    process.env.REACT_APP_SEO === 'true' && require('../../corporations/Corporations').default;
const Auth = process.env.REACT_APP_SEO === 'true' && require('../../Auth').default;
const Chat = process.env.REACT_APP_SEO === 'true' && require('../../Chat').default;
const Manual = process.env.REACT_APP_SEO === 'true' && require('../../manual/Manual').default;
const Content = process.env.REACT_APP_SEO === 'true' && require('../../content/Content').default;
const Profile = process.env.REACT_APP_SEO === 'true' && require('../../profile/Profile').default;
const Settings = process.env.REACT_APP_SEO === 'true' && require('../../settings/Settings').default;
const Joins = process.env.REACT_APP_SEO === 'true' && require('../../joins/Joins').default;
const Pays = process.env.REACT_APP_SEO === 'true' && require('../../pays/Pays').default;
const Recruit = process.env.REACT_APP_SEO === 'true' && require('../../recruit/Recruit').default;
const ModelsPage =
    process.env.REACT_APP_SEO === 'true' &&
    require('@components/crm/modelsPage/ModelsPage').default;
const RecruitDealGroup =
    process.env.REACT_APP_SEO === 'true' &&
    require('../../recruit/components/dealGroup/DealGroup').default;
const ChatTemplates =
    process.env.REACT_APP_SEO === 'true' && require('../../chatTemplates/ChatTemplates').default;
const Mailings = process.env.REACT_APP_SEO === 'true' && require('../../mailings/Mailings').default;
const Pdf = process.env.REACT_APP_SEO === 'true' && require('../../Pdf').default;
const Public = process.env.REACT_APP_SEO === 'true' && require('../../public/Public').default;
const Pep = process.env.REACT_APP_SEO === 'true' && require('../../Pep').default;

const pages = {
    'corporation-index': {
        render(this: AppI) {
            return new LazyPage({ context: this }).renderPage('corporation-index', Index);
        },
    },
    corporations: {
        render(this: AppI) {
            return new LazyPage({ context: this }).renderPage('corporations', Corporations);
        },
    },
    auth: {
        render(this: AppI) {
            return new LazyPage({ context: this }).renderPage('auth', Auth);
        },
    },
    chat: {
        render(this: AppI) {
            return new LazyPage({ context: this }).renderPage('chat', Chat);
        },
    },
    manual: {
        render(this: AppI) {
            return new LazyPage({ context: this }).renderPage('manual', Manual);
        },
    },
    content: {
        render(this: AppI) {
            return new LazyPage({ context: this }).renderPage('content', Content);
        },
    },
    profile: {
        render(this: AppI) {
            return new LazyPage({ context: this }).renderPage('profile', Profile);
        },
    },
    settings: {
        render(this: AppI) {
            return new LazyPage({ context: this }).renderPage('settings', Settings);
        },
    },
    joins: {
        render(this: AppI) {
            return new LazyPage({ context: this }).renderPage('joins', Joins);
        },
    },
    pays: {
        render(this: AppI) {
            return new LazyPage({ context: this }).renderPage('pays', Pays);
        },
    },
    recruit: {
        render(this: AppI) {
            return new LazyPage({ context: this }).renderPage('recruit', Recruit);
        },
    },
    tasks: {
        render(this: AppI) {
            return new LazyPage({ context: this }).renderPage('tasks', ModelsPage, {
                type: 'tasks',
            });
        },
    },
    'recruit-dealGroup': {
        render(this: AppI) {
            return new LazyPage({ context: this }).renderPage(
                'recruit-dealGroup',
                RecruitDealGroup,
            );
        },
    },
    'chat-templates': {
        render(this: AppI) {
            return new LazyPage({ context: this }).renderPage('chat-templates', ChatTemplates);
        },
    },
    mailings: {
        render(this: AppI) {
            return new LazyPage({ context: this }).renderPage('mailings', Mailings);
        },
    },
    pdf: {
        render(this: AppI) {
            return new LazyPage({ context: this }).renderPage('pdf', Pdf);
        },
    },
    public: {
        render(this: AppI) {
            return new LazyPage({ context: this }).renderPage('public', Public);
        },
    },
    pep: {
        render(this: AppI) {
            return new LazyPage({ context: this }).renderPage('pep', Pep);
        },
    },
} as const;

export default pages;
