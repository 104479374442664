import React, { MouseEvent } from 'react';
import { connect } from 'react-redux';

import { ListenerT, StoreT } from '@global/types.ts';

import moveHandler from './methods/moveHandler.ts';

import NewYearCursorI from './types.ts';

class NewYearCursor
    extends React.Component<NewYearCursorI['props'], NewYearCursorI['state']>
    implements NewYearCursorI
{
    parent: NewYearCursorI['parent'];

    constructor(props: NewYearCursorI['props']) {
        super(props);
        this.state = {
            isShow: true,
            isPointer: false,
        };

        this.moveHandler = this.moveHandler.bind(this);

        this.parent = React.createRef();
    }

    x = -100;
    y = -100;

    moveHandler = moveHandler;

    componentDidMount(): void {
        (document.addEventListener as ListenerT<MouseEvent>)('mousemove', this.moveHandler);

        document.addEventListener('mouseenter', () => {
            this.setState({ isShow: true });
        });

        document.addEventListener('mouseleave', () => {
            this.setState({ isShow: false });
        });

        this.moveHandler({ pageX: this.x, pageY: this.y });
    }

    componentWillUnmount(): void {
        (document.removeEventListener as ListenerT<MouseEvent>)('mousemove', this.moveHandler);
    }

    render() {
        const { isShow, isPointer } = this.state;

        return (
            <div ref={this.parent} className={`newYearCursor ${isShow ? '_show' : ''}`}>
                <img
                    src={require('../../img/crm/newYear/cursor.svg').default}
                    alt=""
                    className={`newYearCursor__image ${!isPointer ? '_show' : ''}`}
                />
                <img
                    src={require('../../img/crm/newYear/pointer-cursor.svg').default}
                    alt=""
                    className={`newYearCursor__image _pointer ${isPointer ? '_show' : ''}`}
                />
            </div>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        designMode: state.designMode,
    };
}

export default connect(mapStateToProps)(NewYearCursor);
