import deleteNotification from '@requests/deleteNotification';

import { NotificationInfoT } from '../../types';

const chatResponsible: NotificationInfoT = {
    subTitle: 'Чаты',
    title: 'На вас назначили чат',
    description() {
        return 'Возьмите его в работу';
    },
    buttons() {
        const { notification } = this.props;

        return [
            {
                className: '_blue',
                content: 'Взять в работу',
                pageName: 'chat-inner',
                pageIds: { 3: notification.chatId! },
                handler: this.buttonHandler.bind(
                    this,
                    0,
                    deleteNotification.bind(null, { id: notification._id }),
                ),
            },
        ];
    },
};

export default chatResponsible;
