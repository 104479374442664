import React from 'react';

import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';
import setSpacesInText from '@functions/setSpacesInText.ts';

import checkContent from './methods/checkContent.ts';
import getArticle from './methods/getArticle.ts';
import setView from './methods/setView.ts';

import AnnounceContentI from './types.ts';

import renderFoot from './renders/renderFoot.tsx';
import renderHead from './renders/renderHead.tsx';

class AnnounceContent
    extends React.Component<AnnounceContentI['props'], AnnounceContentI['state']>
    implements AnnounceContentI
{
    parent: AnnounceContentI['parent'];
    timerId: AnnounceContentI['timerId'];

    constructor(props: AnnounceContentI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    getArticle = getArticle;
    setView = setView;
    checkContent = checkContent;

    renderHead = renderHead;
    renderFoot = renderFoot;

    componentDidMount(): void {
        this.getArticle();
    }

    componentWillUnmount(): void {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }
    }

    render() {
        const { article } = this.state;

        return (
            <div
                ref={this.parent}
                className={`v2notificationAnnounce _NOSCROLL ${article ? '_ready' : ''}`}
            >
                <LoaderBlock
                    loaderClassName="_main"
                    className="v2notificationAnnounce__loader"
                    isShow={!article}
                />

                <div className="v2notificationAnnounce__inner _COL">
                    {article && (
                        <>
                            {this.renderHead()}
                            <div className="v2notificationAnnounce__content">
                                <div
                                    className="v2notificationAnnounce__contentInner"
                                    dangerouslySetInnerHTML={{
                                        __html: setSpacesInText(article?.content),
                                    }}
                                ></div>
                            </div>
                            {this.renderFoot()}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default AnnounceContent;
