import React from 'react';

import Button from '@components/button/Button.tsx';
import Error from '@components/error/Error.tsx';
import Icon from '@components/icon/Icon.tsx';
import Input from '@components/input/Input.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';
import setSpacesInText from '@functions/setSpacesInText.ts';

import AlertI, { ContentT } from '../types.ts';

const renderContent: AlertI['renderContent'] = function () {
    const { type, error, loadingKey, denied, important, textMessage = '' } = this.state;
    const content = type ? (this.contents[type] as ContentT) : null;
    const title = typeof content?.title === 'function' ? content?.title.call(this) : content?.title;
    const description =
        typeof content?.description === 'function'
            ? content?.description.call(this)
            : content?.description;
    const otherCancel = content?.otherCancel;
    const prevCancel = content?.prevCancel;

    return (
        <>
            <div className={`v2popup__content _padding _COL _CENTER _NOSCROLL _${type}`}>
                {!content?.textMessage && content && (
                    <i className={`v2popup__icon ${denied || important ? '_important' : ''}`}>
                        <Icon name={content.icon || 'popup-alert'} />
                    </i>
                )}

                <div
                    className="v2popup__title _TEXT _CENTER"
                    dangerouslySetInnerHTML={{ __html: title || '' }}
                ></div>
                <div
                    className="v2popup__description _TEXT _CENTER"
                    dangerouslySetInnerHTML={{
                        __html: description ? setSpacesInText(description) : '',
                    }}
                ></div>
                {content?.textMessage && (
                    <>
                        <div className="v2popup__textMessage">
                            <Input
                                name="textMessage"
                                value={textMessage}
                                onChange={this.textMessageHandler.bind(this)}
                                support={content.textMessage.support}
                                textarea={true}
                            />
                        </div>
                    </>
                )}
                <div className="v2popup__foot _COL _CENTER _top">
                    {important ? (
                        <>
                            <div className="v2popup__button">
                                <Button className="_main" onClick={this.close.bind(this)}>
                                    {content?.buttonText}
                                </Button>
                            </div>
                            <div className="v2popup__cancel _CLICK" onClick={this.save.bind(this)}>
                                {content?.cancelText || 'Продолжить'}
                                <LoaderBlock
                                    className="v2popup__cancelLoader _FULL"
                                    isShow={loadingKey === 'save'}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            {typeof prevCancel === 'function' && prevCancel.call(this)}
                            <div className="v2popup__button">
                                <Button
                                    className="_main"
                                    onClick={this.save.bind(this)}
                                    loading={loadingKey === 'save'}
                                >
                                    {content?.buttonText || 'Понятно!'}
                                </Button>
                            </div>
                            {typeof otherCancel === 'function' ? (
                                <>{otherCancel.call(this)}</>
                            ) : (
                                <>
                                    {!denied && (
                                        <div
                                            className="v2popup__cancel _CLICK"
                                            onClick={this.close.bind(this)}
                                        >
                                            {content?.cancelText || 'Отменить'}
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}

                    <Error className="v2popup__error _center" error={error?.text} />
                </div>
            </div>
        </>
    );
};

export default renderContent;
