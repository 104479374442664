import React from 'react';

import handlerPopup from '@functions/handlerPopup.ts';

import { RenderBlockT } from '../../types.ts';

const start: RenderBlockT = function () {
    const { user } = this.props;

    return (
        <>
            <div className="newYearPopup__info _COL">
                <img
                    src={require('../../../../../img/crm/newYear/decor.svg').default}
                    alt=""
                    className="newYearPopup__infoIcon"
                />
                <div className="newYearPopup__infoSupport">ИТОГИ 2024</div>
                <div className="newYearPopup__infoTitle">
                    С наступающим
                    <br />
                    Новым Годом, {user?.firstName}!
                </div>
                <p className="newYearPopup__infoText">
                    Уходящий год был насыщенным на перемены
                    <br />и достижения. Давай подведём итоги года вместе?
                </p>
                <div className="newYearPopup__infoButtons _ROW">
                    <div
                        className="newYearPopup__infoButton _CLICK _main"
                        onClick={() => {
                            this.setCurrentBlock('join');
                        }}
                    >
                        Проводить 2024-й
                    </div>
                    <div
                        className="newYearPopup__infoButton _CLICK _sub"
                        onClick={() => {
                            handlerPopup('newYearPopup', { isShow: false });
                            localStorage.setItem('showNewYear', 'true');
                        }}
                    >
                        Позже
                    </div>
                </div>
            </div>
        </>
    );
};

export default start;
