import React from 'react';

// import Animate from '@components/animate/Animate.tsx';
import SupportChatBtn from '@components/crm/SupportChatBtn.jsx';

// import NewYearStatus from '@components/newYearStatus/NewYearStatus.tsx';
import I from '../types.ts';

const renderOther: I['renderOther'] = function () {
    // const { user } = this.props;

    return (
        <>
            <SupportChatBtn />
            {/* <Animate
                className="body__newYearStatus"
                isShow={!!(user && user.corporations.find((item) => item.id === 'admin'))}
            >
                <NewYearStatus />
            </Animate> */}
        </>
    );
};

export default renderOther;
