import React from 'react';

import handlerPopup from '@functions/handlerPopup.ts';

import { RenderBlockT } from '../../types.ts';

const result: RenderBlockT = function () {
    const { user } = this.props;

    return (
        <>
            <div className="newYearPopup__info _COL">
                <img
                    src={require('../../../../../img/crm/newYear/decor.svg').default}
                    alt=""
                    className="newYearPopup__infoIcon"
                />
                <div className="newYearPopup__infoSupport">ИТОГИ 2024</div>
                <div className="newYearPopup__infoTitle">
                    Спасибо за этот год, {user?.firstName}!
                    <br />
                    Мы сделали это вместе!
                </div>
                <p className="newYearPopup__infoText">
                    Давай и дальше покорять новые вершины
                    <br />в Новом году вместе! С наступающим!
                </p>
                <div className="newYearPopup__infoButtons _ROW">
                    <div
                        className="newYearPopup__infoButton _CLICK _main"
                        onClick={() => {
                            handlerPopup('newYearPopup', { isShow: false });
                            localStorage.setItem('showNewYear', 'true');
                        }}
                    >
                        А теперь работать
                    </div>
                    <div
                        className="newYearPopup__infoButton _CLICK"
                        onClick={() => {
                            this.setCurrentBlock('start');
                        }}
                    >
                        Посмотреть снова
                    </div>
                </div>
            </div>
        </>
    );
};

export default result;
