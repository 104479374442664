import React from 'react';

import getDateText from '@functions/getDateText.ts';

import I from '../types.ts';

const renderHead: I['renderHead'] = function () {
    const { updatedDateKey } = this.state;
    const { notification } = this.props;
    const notificationInfo = this.notifications[notification.type];

    return (
        <div className="v2notification__head _ROW">
            <div className="v2notification__subTitle">{notificationInfo.subTitle}</div>
            <div className="v2notification__date" key={updatedDateKey}>
                {getDateText(new Date(notification.updatedDateTime!))}
            </div>
        </div>
    );
};

export default renderHead;
