import React from 'react';

import Button from '@components/button/Button.tsx';
import changePage from '@functions/changePage.ts';

import I from '../types.ts';

const renderButtons: I['renderButtons'] = function () {
    const { loadingKey } = this.state;
    const { notification } = this.props;
    const notificationInfo = this.notifications[notification.type];
    const { buttons } = notificationInfo;

    if (!buttons) {
        return <></>;
    }

    return (
        <div className="v2notification__buttons _ROW">
            {buttons.call(this).map((button, buttonIndex) => {
                const { handler, pageName } = button;

                return (
                    <div className="v2notification__button" key={buttonIndex}>
                        <Button
                            className={`_minSize ${button.className}`}
                            onClick={async () => {
                                if (handler) {
                                    await handler.call(this);
                                }

                                if (pageName) {
                                    await changePage({ pageName, ids: button.pageIds });
                                }
                            }}
                            loading={loadingKey === buttonIndex}
                        >
                            {button.content}
                        </Button>
                    </div>
                );
            })}
        </div>
    );
};

export default renderButtons;
