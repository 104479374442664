import handlerLoading from '@functions/handlerLoading.ts';

import I from '../types.ts';

const buttonHandler: I['buttonHandler'] = async function (index, fn) {
    await handlerLoading.call(this, index);

    try {
        await fn();
    } catch (error) {}

    await handlerLoading.call(this, undefined);
};

export default buttonHandler;
