import shortText from '@functions/shortText';

import { NotificationInfoT } from '../../types';

const alertTask: NotificationInfoT = {
    subTitle: 'Задачи',
    important: true,
    title() {
        const { notification } = this.props;

        return `Задача №${notification.taskNumber} просрочена`;
    },
    description() {
        const { notification } = this.props;

        return `Описание: ${shortText({ str: notification.taskDescription!, stop: 20, type: 'word' })}`;
    },
    buttons() {
        const { notification } = this.props;

        return [
            {
                className: '_blue',
                content: 'Подробнее',
                pageName: 'tasks-inner',
                pageIds: { 2: notification.taskId! },
            },
        ];
    },
};

export default alertTask;
