import React from 'react';
import { connect } from 'react-redux';

import setPage from './methods/setPage.ts';
import showAnnounce from './methods/showAnnounce.ts';

import NotificationBarI from './types.ts';

import renderContent from './renders/renderContent.tsx';
import renderHead from './renders/renderHead.tsx';
import renderPage from './renders/renderPage.tsx';
import pages from './static/pages.tsx';

class NotificationBar
    extends React.Component<NotificationBarI['props'], NotificationBarI['state']>
    implements NotificationBarI
{
    parent: NotificationBarI['parent'];

    constructor(props: NotificationBarI['props']) {
        super(props);
        this.state = {
            currentPage: 'notifications',
        };

        this.parent = React.createRef();
    }

    pages = pages;

    showAnnounce = showAnnounce;
    setPage = setPage;

    renderHead = renderHead;
    renderContent = renderContent;
    renderPage = renderPage;

    render() {
        return (
            <div ref={this.parent} className="v2notificationBar">
                {this.renderHead()}
                {this.renderContent()}
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(NotificationBar);
