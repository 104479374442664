import getUser from '@functions/getUser.js';
import { getCookie } from '@functions/handlerCookies.js';
import resize from '@functions/handlerSize.js';

import I from '../types.ts';

const visibilityDocChange: I['visibilityDocChange'] = function ({ detail: { isActive } }) {
    const { user } = this.props;

    if (isActive && user) {
        getUser(getCookie(process.env.REACT_APP_HASH), true, true).then(
            () => null,
            () => null,
        );

        resize();

        setTimeout(() => {
            resize();
        }, 10);
    }
};

export default visibilityDocChange;
