import React from 'react';

import Avatar from '@components/avatar/Avatar.tsx';
import Icon from '@components/icon/Icon.tsx';
import Link from '@components/link/Link.tsx';
import NewYearBorder from '@components/newYearBorder/NewYearBorder.tsx';
import getUserName from '@functions/getUserName.ts';
import logout from '@functions/logout';

import I from '../types.ts';

const renderFoot: I['renderFoot'] = function () {
    const { user } = this.state;
    const userName = user ? getUserName({ user, type: 'reverseShort' }) : '';
    const corporation = this.getCorporation();

    return (
        <div className="v2sideBar__foot">
            <div className="v2sideBar__footUser _COL">
                <div className="v2sideBar__footUserAvatar">
                    <NewYearBorder radius="50%" />
                    <Avatar
                        image={user?.logo?.fullSrc}
                        firstSymb={user?.secondName?.[0]}
                        secondSymb={user?.firstName?.[0]}
                    />
                </div>
                <div className="v2sideBar__footUserName">{userName}</div>
                <div className="v2sideBar__footUserRole">{corporation?.roleName}</div>
            </div>
            <div className="v2sideBar__footActions">
                <div className="v2sideBar__footActionsInner _COL">
                    <Link
                        className="v2sideBar__footActionsButton _CLICK _ROW _edit"
                        pageName="profile"
                    >
                        <i className="v2sideBar__footActionsButtonIcon">
                            <Icon name="actions-edit" />
                        </i>
                        Редактировать
                    </Link>
                    <div
                        className="v2sideBar__footActionsButton _CLICK _ROW _exit"
                        onClick={() => logout()}
                    >
                        <i className="v2sideBar__footActionsButtonIcon">
                            <Icon name="actions-exit" />
                        </i>
                        Выйти
                    </div>
                    <Link className="v2sideBar__footActionsRules" pageName="public-legal">
                        Правовая информация
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default renderFoot;
