import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Icon from '@components/icon/Icon.tsx';

import NotificationCountI from './types.ts';

class NotificationCount
    extends React.Component<NotificationCountI['props'], NotificationCountI['state']>
    implements NotificationCountI
{
    parent: NotificationCountI['parent'];

    constructor(props: NotificationCountI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { counter, handler } = this.props;

        return (
            <div ref={this.parent} className={`v2notificationCount ${counter > 0 ? '_shake' : ''}`}>
                <div
                    className="v2notificationCount__inner _COL _CLICK"
                    onClick={() => {
                        handler();
                    }}
                >
                    <i className="v2notificationCount__icon">
                        <Icon name="bar-notifications" />
                    </i>
                    <AnimateChange className="v2notificationCount__counter" renderKey={counter}>
                        {counter}
                    </AnimateChange>
                </div>
            </div>
        );
    }
}

export default NotificationCount;
