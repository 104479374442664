import setAsyncState from '@functions/setAsyncState.ts';

import I from '../types.ts';

const socketHandler: I['socketHandler'] = async function ({
    detail: {
        name,
        data: { id, action, article },
    },
}) {
    if (name === 'userArticles') {
        if (action === 'delete') {
            await this.deleteItem({ id });
        } else if (article) {
            await this.addItem({ item: article! });
        }

        await setAsyncState.call(this, { updatedListKey: new Date().getTime() });
    }
};

export default socketHandler;
