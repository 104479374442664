import I from '../types.ts';

const init: I['init'] = function () {
    const { count } = this.props;
    let i = count;

    this.flakes = [];

    const size = this.getParentSize();

    while (i--) {
        const x = this.randomBetween(0, size.width, true);
        const y = this.randomBetween(0, size.height, true);

        const flake = this.createFlake(x, y);

        this.flakes.push(flake);
    }

    this.scaleCanvas();
    this.loop();
};

export default init;
