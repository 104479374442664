import React from 'react';

import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { currentPage } = this.state;

    return (
        <div className="v2notificationBar__content">
            <ListAbsoluteMain
                className="v2notificationBar__pages _ITEMS"
                items={[{ key: currentPage }]}
                renderItem={this.renderPage.bind(this)}
                classNameItem="v2notificationBar__page"
                prop="key"
                paramsParent={{ width: true }}
                styles={[]}
                isNotParams={true}
                isNotParamsItem={true}
                allItems={Object.keys(this.pages)}
                currentItemKey={currentPage}
            />
        </div>
    );
};

export default renderContent;
