import React from 'react';

import NotificationWrapper from '@components/crm/notificationWrapper/NotificationWrapper.tsx';

import I from '../types.ts';

const renderNotificationbar: I['renderNotificationbar'] = function () {
    return <NotificationWrapper />;
};

export default renderNotificationbar;
