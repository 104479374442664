import React from 'react';

import { RenderBlockT } from '../../types.ts';

const tax: RenderBlockT = function () {
    return (
        <>
            <div className="newYearPopup__stats _COL">
                <div className="newYearPopup__statsCount">
                    <img
                        src={require('../../../../../img/crm/newYear/stats-tax.svg').default}
                        alt=""
                        className="newYearPopup__statsIcon"
                    />
                    <span></span>
                </div>
                <div className="newYearPopup__statsDescription">
                    <b>оплачено налогов</b>
                    <br />
                    за самозанятых
                </div>
            </div>
        </>
    );
};

export default tax;
