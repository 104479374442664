import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Icon from '@components/icon/Icon.tsx';
import handlerPopup from '@functions/handlerPopup.ts';
import { setCursorSupport } from '@functions/supportHandler.ts';
import { FilterQueryT } from '@global/types.ts';
import { dispatcher } from '@redux/redux.ts';

import I from '../types.ts';

const renderActionsHead: I['renderActionsHead'] = function () {
    const { filter, filterQuery } = this.state;
    const { isHideAudioNotification, setNotificationsQuery } = this.props;
    const filterCounter = this.getFilterCounter();
    const supportProps = setCursorSupport(
        {
            _id: `notificationsFilter`,
            content: 'Фильтр',
            dir: 'bottom',
        },
        { targetClassName: '.v2notificationBar__filterButton', className: '_upper' },
    );

    return (
        <div className="v2notificationBar__pageHead _ROW">
            <div
                className={`v2notificationBar__notifyButton _CLICK _ROW ${isHideAudioNotification ? '_hide' : ''}`}
                onClick={() => {
                    dispatcher<'crm'>({
                        type: 'isHideAudioNotification',
                        data: !isHideAudioNotification,
                    });

                    if (isHideAudioNotification) {
                        localStorage.removeItem('isHideAudioNotification');
                    } else {
                        localStorage.setItem('isHideAudioNotification', 'true');
                    }
                }}
            >
                <div className="v2notificationBar__notifyButtonIcon">
                    <Icon name="notifications" />
                </div>
                <AnimateChange
                    className="v2notificationBar__notifyButtonContent"
                    renderKey={!!isHideAudioNotification}
                >
                    <>
                        <b>{isHideAudioNotification ? 'Включить' : 'Выключить'}</b> звуковое
                        оповещение
                    </>
                </AnimateChange>
            </div>
            <div
                className="v2notificationBar__filterButton _COL _CLICK"
                {...supportProps}
                onClick={() => {
                    handlerPopup('filterPopup', {
                        isShow: true,
                        filter,
                        filterQuery,
                        name: 'notifications',
                        callback: (result: FilterQueryT[]) => {
                            setNotificationsQuery(result);

                            this.setFilter(result);

                            localStorage.setItem(this.getLocalFilterName(), JSON.stringify(result));
                        },
                    });
                }}
            >
                <AnimateChange
                    className="v2notificationBar__filterButtonCounter"
                    renderKey={filterCounter || undefined}
                    styles={['width']}
                >
                    <>{filterCounter}</>
                </AnimateChange>
                <i className="v2notificationBar__filterButtonIcon">
                    <Icon name="widget-filter" />
                </i>
            </div>
        </div>
    );
};

export default renderActionsHead;
