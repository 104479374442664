import I from '../types.ts';

const playMusic: I['playMusic'] = function (id) {
    const { isHideAudioNotification } = this.props;

    if (!isHideAudioNotification && !this.notificationsTimers[id]) {
        const audio = new Audio();

        audio.addEventListener('canplaythrough', () => {
            audio.play().then(
                () => {
                    const notificationNode = document
                        .querySelector(`.v2notificationBar__pageItem[data-_id="${id}"]`)
                        ?.querySelector('.v2notification') as HTMLElement;

                    if (notificationNode) {
                        notificationNode.classList.add('_shake');

                        this.notificationsTimers[id] = setTimeout(() => {
                            notificationNode.classList.remove('_shake');

                            delete this.notificationsTimers[id];
                        }, 800);
                    }
                },
                (err) => {
                    console.log('Error notification music', err);
                },
            );
        });

        audio.src = require(`../../../../img/notify.mp3`);
    }
};

export default playMusic;
