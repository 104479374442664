import newVersionPopupHandler from '@functions/crm/newVersionPopupHandler.ts';
import { dispatcher } from '@redux/redux.ts';

import I from '../types.ts';

const socketHandler: I['socketHandler'] = async function ({ detail }) {
    const { user } = this.props;
    const { name, data } = detail;

    if (name === 'system') {
        const version = data.version as number;

        if (version !== +process.env.REACT_APP_CRM_VERSION!) {
            dispatcher({ type: 'user', data: { ...user, systemVersion: version } }).then(() => {
                newVersionPopupHandler({ version });
            });
        }
    }
};

export default socketHandler;
