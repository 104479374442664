import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import getDateText from '@functions/getDateText.ts';
import setSpacesInText from '@functions/setSpacesInText.ts';

import I from '../types.ts';

const renderHead: I['renderHead'] = function () {
    const article = this.state.article!;
    const { setPage } = this.props;

    return (
        <div className="v2notificationAnnounce__head _COL">
            <div
                className="v2notificationAnnounce__headBack _ROW _CLICK"
                onClick={() => {
                    setPage('announces');
                }}
            >
                <i className="v2notificationAnnounce__headBackIcon">
                    <Icon name="arrow-prev" />
                </i>
                Назад
            </div>
            <div className="v2notificationAnnounce__headType">{article.sectionText}</div>
            <div
                className="v2notificationAnnounce__headTitle"
                dangerouslySetInnerHTML={{ __html: setSpacesInText(article.title) }}
            ></div>
            <div className="v2notificationAnnounce__headInfo _ROW">
                <p className="v2notificationAnnounce__headDate">
                    {getDateText(article.createdDate)}
                </p>
                <div className="v2notificationAnnounce__headView _ROW">
                    <i className="v2notificationAnnounce__headViewIcon">
                        <Icon name="blog-view" />
                    </i>
                    {article.viewCounter}
                </div>
            </div>
        </div>
    );
};

export default renderHead;
