import { NotificationInfoT } from '../../types';

const executorJoin: NotificationInfoT = {
    subTitle: 'Подключения',
    title: 'Подключен новый исполнитель',
    description() {
        const { notification } = this.props;
        const { executorName, executorPhone } = notification;

        return `<b>${executorName}</b>, <span class="_noWrap">${executorPhone}</span>`;
    },
    buttons() {
        const { notification } = this.props;

        return [
            {
                className: '_blue',
                content: 'Подключить',
                pageName: 'manual-executors-inner-docs',
                pageIds: { 3: notification.executorId! },
            },
        ];
    },
};

export default executorJoin;
