import alertTask from './notifications/alertTask';
import chatResponsible from './notifications/chatResponsible';
import executorJoin from './notifications/executorJoin';
import newMessage from './notifications/newMessage';
import newTask from './notifications/newTask';

const notifications = {
    executorJoin,
    newMessage,
    chatResponsible,
    newTask,
    alertTask,
} as const;

export default notifications;
