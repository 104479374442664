import AlertI from '../../types';

const deleteBeelineUser = {
    title: 'Подтвердите <br />удаление аккаунта',
    description(this: AlertI) {
        const { info } = this.state;

        return `Данное действие нельзя отменить. Вы уверены, что хотите удалить аккаунт <b class="_noWrap">${info}</b>?`;
    },
    buttonText: 'Удалить аккаунт',
} as const;

export default deleteBeelineUser;
