import LazyPageI from '@classes/lazyPage/types.ts';

const loadCrmPublic: LazyPageI['loadComponent'] = async function (name) {
    let Component;

    if (name === 'public-business') {
        Component = (await import('../../../../views/crm/public/components/business/Business'))
            .default;
    }

    if (name === 'public-executors') {
        Component = (await import('../../../../views/crm/public/components/executors/Executors'))
            .default;
    }

    if (name === 'public-blog') {
        Component = (await import('../../../../views/crm/public/components/blog/Blog')).default;
    }

    if (name === 'public-legal') {
        Component = (await import('../../../../views/crm/public/components/legal/Legal')).default;
    }

    return Component;
};

export default loadCrmPublic;
