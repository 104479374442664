import LazyPageI from '@classes/lazyPage/types.ts';

const loadCrmSettings: LazyPageI['loadComponent'] = async function (name) {
    let Component;

    if (name === 'settings-main') {
        Component = (await import('../../../../views/crm/settings/components/main/Main')).default;
    }

    if (name === 'settings-operations' || name === 'settings-incomes') {
        Component = (await import('../../../../views/crm/settings/components/balance/Balance'))
            .default;
    }

    if (name === 'settings-docs') {
        Component = (await import('../../../../views/crm/settings/components/docs/Docs')).default;
    }

    if (name === 'settings-users') {
        Component = (await import('../../../../views/crm/settings/components/users/Users')).default;
    }

    if (name === 'settings-roles') {
        Component = (await import('../../../../views/crm/settings/components/roles/Roles')).default;
    }

    if (name === 'settings-proxy') {
        Component = (await import('../../../../views/crm/joins/components/contracts/Contracts'))
            .default;
    }

    if (name === 'settings-integrations') {
        Component = (
            await import('../../../../views/crm/settings/components/integrations/Integrations')
        ).default;
    }

    if (name === 'settings-reports') {
        Component = (await import('../../../../views/crm/settings/components/reports/Reports'))
            .default;
    }

    if (name === 'settings-logs') {
        Component = (await import('../../../../views/crm/settings/components/logs/Logs')).default;
    }

    return Component;
};

export default loadCrmSettings;
