import I from '../types.ts';

const randomBetween: I['randomBetween'] = function (min, max, round) {
    const num = Math.random() * (max - min + 1) + min;

    if (round) {
        return Math.floor(num);
    }

    return num;
};

export default randomBetween;
