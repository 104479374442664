import axios from 'axios';

import getHeaders from '@functions/getHeaders';
import { ReqResponseT } from '@global/types';

type ParamsT = {
    id: string;
};

export default async function deleteNotification({ id }: ParamsT): Promise<void> {
    const response = await axios.delete<{}, { data: ReqResponseT<{}> }>(
        `${process.env.REACT_APP_API}/notifications?id=${id}&force=true`,
        { headers: getHeaders() },
    );
    const { success } = response.data;

    if (success) {
        return;
    }

    return Promise.reject();
}
