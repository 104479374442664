import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import SideBar from '@components/crm/sideBar/SideBar.tsx';
import getCurrentPage from '@functions/getCurrentPage.ts';
import getPage from '@functions/getPage.ts';

import I from '../types.ts';

const renderSidebar: I['renderSidebar'] = function () {
    const { user, storePages } = this.props;
    const currentPageName = getCurrentPage({
        storePages,
        filter: (page) => !page.level,
    })!;
    const currentPage = getPage({ name: currentPageName });

    return (
        <Animate
            className="body__sideBar"
            isShow={currentPage?.group === 'cabinet' && !!user && user.corporations.length > 0}
        >
            <SideBar />
        </Animate>
    );
};

export default renderSidebar;
