import I from '../types.ts';

const checkChange: I['checkChange'] = function () {
    const { renderKey, updateKey } = this.props;

    if (renderKey !== this.renderKey || updateKey !== this.updateKey) {
        this.updateItems({
            isRender: renderKey !== this.renderKey,
            isUpdate: updateKey !== this.updateKey,
        });

        this.renderKey = renderKey;
        this.updateKey = updateKey;
    }
};

export default checkChange;
