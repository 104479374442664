import React from 'react';

import { RenderBlockT } from '../../types.ts';

const blog: RenderBlockT = function () {
    return (
        <>
            <div className="newYearPopup__stats _COL">
                <div className="newYearPopup__statsCount">
                    <img
                        src={require('../../../../../img/crm/newYear/stats-blog.svg').default}
                        alt=""
                        className="newYearPopup__statsIcon"
                    />
                    <span></span>
                </div>
                <div className="newYearPopup__statsDescription">
                    <b>cтатей в блоге</b>
                    <br />
                    CRM LIVECARGO
                </div>
            </div>
        </>
    );
};

export default blog;
