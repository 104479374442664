import AlertI from '../../types';

const deleteIntegration = {
    title: 'Подтвердите <br />удаление интеграции',
    description(this: AlertI) {
        const { info } = this.state;

        return `Данное действие нельзя отменить. Вы уверены, что хотите удалить интеграцию <b>${info}</b>?`;
    },
    buttonText: 'Удалить интеграцию',
} as const;

export default deleteIntegration;
