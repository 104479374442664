import I from '../types.ts';

const checkContent: I['checkContent'] = function (content) {
    const div = document.createElement('div');

    div.innerHTML = content;

    div.querySelectorAll('div').forEach((innerDiv) => {
        if (
            innerDiv.innerText?.replace(/\u200b/gi, '')?.trim().length === 0 &&
            !innerDiv.querySelector('img')
        ) {
            innerDiv.classList.add('_empty');
        }
    });

    return div.innerHTML;
};

export default checkContent;
