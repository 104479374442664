import React from 'react';

import buttonHandler from './methods/buttonHandler.ts';

import NotificationI from './types.ts';

import renderButtons from './renders/renderButtons.tsx';
import renderContent from './renders/renderContent.tsx';
import renderHead from './renders/renderHead.tsx';
import notifications from './static/notifications.ts';

class Notification
    extends React.Component<NotificationI['props'], NotificationI['state']>
    implements NotificationI
{
    parent: NotificationI['parent'];
    intervalId: NotificationI['intervalId'];

    constructor(props: NotificationI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    notifications = notifications;

    buttonHandler = buttonHandler;

    renderHead = renderHead;
    renderContent = renderContent;
    renderButtons = renderButtons;

    componentDidMount(): void {
        this.intervalId = setInterval(() => {
            this.setState({ updatedDateKey: new Date().getTime() });
        }, 60_000);
    }

    componentWillUnmount(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    render() {
        const { notification } = this.props;
        const notificationInfo = this.notifications[notification.type];
        const { important } = notificationInfo;

        return (
            <div ref={this.parent} className={`v2notification _COL ${important ? '_important' : ''}`}>
                {this.renderHead()}
                {this.renderContent()}
                {this.renderButtons()}
            </div>
        );
    }
}

export default Notification;
