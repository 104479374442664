import I from '../types.ts';

const setNavListPosition: I['setNavListPosition'] = function ({ name }) {
    const contentNode = this.parent.current!.querySelector(
        '.v2sideBar__contentInner',
    ) as HTMLElement;

    const listNode = this.parent.current!.querySelector(
        `.v2sideBar__navList._${name}`,
    ) as HTMLElement;
    const itemNode = this.parent.current!.querySelector(
        `.v2sideBar__navItem._${name}`,
    ) as HTMLElement;

    if (listNode && itemNode) {
        const itemTop = itemNode.getBoundingClientRect().y - contentNode.getBoundingClientRect().y;

        listNode.style.top = `${itemTop}px`;
        listNode.removeAttribute('data-bottom');

        if (
            listNode.getBoundingClientRect().y + listNode.offsetHeight >
            contentNode.getBoundingClientRect().y + contentNode.offsetHeight
        ) {
            const resultTop = itemTop - listNode.offsetHeight + itemNode.offsetHeight;

            listNode.style.top = `${resultTop}px`;
            listNode.setAttribute('data-bottom', 'true');
        }
    }
};

export default setNavListPosition;
