import setAsyncTimer from '@functions/setAsyncTimer.ts';
import NotificationT from '@global/models/Notification.ts';
import getListItems from '@requests/getListItems.ts';

import I from '../types.ts';

const getItems: I['getItems'] = async function () {
    const query = [
        ...this.getQueryForRequest().params,
        { key: 'type', value: 'announce' },
        { key: 'system', value: 'crm' },
    ];

    try {
        if (0) {
            await setAsyncTimer(1_000);
        }

        const { items, filter, isLimit, counter } = await getListItems<NotificationT>({
            url: 'userArticle',
            query,
        });

        await this.setItems({ items, filter, counter, isLimit });

        return { items };
    } catch (error) {
        return Promise.reject();
    }
};

export default getItems;
