import React from 'react';

import { RenderBlockT } from '../../types.ts';

const thanks: RenderBlockT = function () {
    return (
        <>
            <div className="newYearPopup__stats _COL">
                <div className="newYearPopup__statsCount">
                    <img
                        src={require('../../../../../img/crm/newYear/stats-thanks.svg').default}
                        alt=""
                        className="newYearPopup__statsIcon"
                    />
                    <span></span>
                </div>
                <div className="newYearPopup__statsDescription">
                    раз исполнители
                    <br />
                    сказали <b>спасибо!</b>
                </div>
            </div>
        </>
    );
};

export default thanks;
