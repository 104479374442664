import MobPopupI from '../types.ts';

const getScroll: MobPopupI['getScroll'] = function () {
    const inner = this.getInner();

    if (!inner) {
        return 0;
    }

    const innerScroll = inner.querySelector('.v2popup__mobBox');

    if (innerScroll) {
        return innerScroll.scrollTop;
    }

    return 0;
};

export default getScroll;
