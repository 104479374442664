import LazyPageI from '@classes/lazyPage/types.ts';

const loadCrmPays: LazyPageI['loadComponent'] = async function (name) {
    let Component;

    if (name === 'pays-main') {
        Component = (await import('../../../../views/crm/pays/components/main/Main')).default;
    }

    if (name === 'pays-groups') {
        Component = (await import('../../../../views/crm/pays/components/groups/Groups')).default;
    }

    if (name === 'pays-tax') {
        Component = (await import('../../../../views/crm/pays/components/tax/Tax')).default;
    }

    if (name === 'pays-limits') {
        Component = (await import('../../../../views/crm/pays/components/limits/Limits')).default;
    }

    if (name === 'pays-projects') {
        Component = (await import('../../../../components/crm/modelsPage/ModelsPage')).default;
    }

    return Component;
};

export default loadCrmPays;
