import setAsyncState from '@functions/setAsyncState.ts';

import I from '../types.ts';

const setLocalFilter: I['setLocalFilter'] = async function () {
    const { setNotificationsQuery } = this.props;
    const localFilter = localStorage.getItem(this.getLocalFilterName());

    await setAsyncState.call(this, { filterQuery: localFilter ? JSON.parse(localFilter) : [] });
    await setNotificationsQuery(localFilter ? JSON.parse(localFilter) : []);
};

export default setLocalFilter;
