import AlertI from '../../types';

const deleteTask = {
    title: 'Подтвердите удаление задачи',
    description(this: AlertI) {
        const { info } = this.state;

        return `Данное действие нельзя отменить. Вы уверены, что хотите удалить задачу <b>${info}</b>?`;
    },
    buttonText: 'Удалить задачу',
} as const;

export default deleteTask;
