import React from 'react';

import I from '../types.ts';

const renderFoot: I['renderFoot'] = function () {
    return (
        <div className="v2notificationAnnounce__foot _ROW">
            <img
                src={require('../../../../../../img/emodzi/victory-hand.png')}
                alt=""
                className="v2notificationAnnounce__footIcon"
            />
            Команда CRM LIVECARGO
        </div>
    );
};

export default renderFoot;
