import I from '../types.ts';

const createFlake: I['createFlake'] = function (x, y) {
    const { speed } = this.props;
    const maxWeight = 2;

    const weight = this.randomBetween(1.5, maxWeight);

    const newFlake = {
        x,
        y,
        r: this.randomBetween(0, 1),
        a: this.randomBetween(0, Math.PI),
        aStep: 0.01,
        weight,
        alpha: 0.035 + this.randomBetween(0, 1) * 0.05,
        speed: (weight / maxWeight) * speed,
    };

    return newFlake;
};

export default createFlake;
