import I from '../types.ts';

const scaleCanvas: I['scaleCanvas'] = function () {
    const canvas = this.canvas.current!;

    const size = this.getParentSize();

    canvas.width = size.width;
    canvas.height = size.height;
};

export default scaleCanvas;
