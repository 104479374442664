type ListT = {
    table: string;
};

const lists = {
    tagsGroups: { table: 'listTagsGroups' },
    tagsGroupsColors: { table: 'listTagsGroupsColors' },
    executors: { table: 'listExecutors' },
    corporations: { table: 'listCorporations' },
    joinScripts: { table: 'listJoinScripts' },
    users: { table: 'listUsers' },
    joinTemplates: { table: 'listJoinTemplates' },
    payProjects: { table: 'listPayProjects' },
    roles: { table: 'listRoles' },
    countries: { table: 'listCountries' },
    passportViews: { table: 'listPassportViews' },
    staticList: { table: 'staticList' },
    countryTypes: { table: 'listCountryTypes' },
    tags: { table: 'listTags' },
    executorTypes: { table: 'listExecutorTypes' },
    executorOrganizations: { table: 'listExecutorOrganizations' },
    cities: { table: 'listCities' },
    docTypes: { table: 'listDocTypes' },
    executorMvdStatuses: { table: 'listExecutorMvdStatuses' },
    integrationNames: { table: 'listIntegrationNames' },
    dealGroups: { table: 'listDealGroups' },
    dealColors: { table: 'listDealColors' },
    dealTriggers: { table: 'listDealTriggers' },
    taskKeys: { table: 'listTaskKeys' },
    taskTimes: { table: 'listTaskTimes' },
    taskModels: { table: 'listTaskModels' },
    pays: { table: 'listPays' },
    deals: { table: 'listDeals' },
    articleSystems: { table: 'listArticleSystems' },
    articleCrmBlogSections: { table: 'listArticleCrmBlogSections' },
    articleCrmAnnounceSections: { table: 'listArticleCrmAnnounceSections' },
    articlePublicateTypes: { table: 'listArticlePublicateTypes' },
} as const;

export default lists;

export type { ListT };
