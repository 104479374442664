import React from 'react';

import AnnounceI from './types.ts';

import renderContent from './renders/renderContent.tsx';
import renderHead from './renders/renderHead.tsx';

class Announce
    extends React.Component<AnnounceI['props'], AnnounceI['state']>
    implements AnnounceI
{
    parent: AnnounceI['parent'];
    intervalId: AnnounceI['intervalId'];

    constructor(props: AnnounceI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    renderHead = renderHead;
    renderContent = renderContent;

    componentDidMount(): void {
        this.intervalId = setInterval(() => {
            this.setState({ updatedDateKey: new Date().getTime() });
        }, 60_000);
    }

    componentWillUnmount(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    render() {
        const { announce } = this.props;
        return (
            <div
                ref={this.parent}
                className={`v2notification _COL ${announce.sortKey === 0 ? '_notRead' : '_read'}`}
            >
                {this.renderHead()}
                {this.renderContent()}
            </div>
        );
    }
}

export default Announce;
