import AlertI from '../../types';

const deleteArticle = {
    title: 'Подтвердите <br />удаление статьи',
    description(this: AlertI) {
        const { info } = this.state;

        return `Данное действие нельзя отменить. Вы уверены, что хотите удалить статью <b>${info}</b>?`;
    },
    buttonText: 'Удалить статью',
} as const;

export default deleteArticle;
