import blog from '../renders/blocks/blog';
import chats from '../renders/blocks/chats';
import clients from '../renders/blocks/clients';
import fns from '../renders/blocks/fns';
import join from '../renders/blocks/join';
import mvd from '../renders/blocks/mvd';
import pays from '../renders/blocks/pays';
import result from '../renders/blocks/result';
import start from '../renders/blocks/start';
import tax from '../renders/blocks/tax';
import thanks from '../renders/blocks/thanks';
import transactions from '../renders/blocks/transactions';
import { RenderBlockT } from '../types';

type BlockT = {
    render: RenderBlockT;
    count?: number;
    round?: number;
    end?: string;
};

const blocks = {
    start: {
        render: start,
    },
    join: {
        render: join,
        count: 19_000,
        round: 100,
        end: ' +',
    },
    pays: {
        render: pays,
        count: 115_000,
        round: 1_000,
        end: ' +',
    },
    fns: {
        render: fns,
        count: 7_500,
        round: 100,
        end: ' +',
    },
    mvd: {
        render: mvd,
        count: 600,
        round: 10,
        end: ' +',
    },
    transactions: {
        render: transactions,
        count: 200_000,
        round: 1_000,
        end: ' +',
    },
    chats: {
        render: chats,
        count: 640_000,
        round: 1_000,
        end: ' +',
    },
    blog: {
        render: blog,
        count: 20,
        round: 1,
    },
    thanks: {
        render: thanks,
        count: 7_500,
        round: 100,
        end: ' +',
    },
    tax: {
        render: tax,
        count: 1_700_000,
        round: 10_000,
        end: ' ₽',
    },
    clients: {
        render: clients,
        count: 8,
        round: 1,
    },
    result: {
        render: result,
    },
} as const;

const blocksOrder = [
    'start',
    'join',
    'pays',
    'fns',
    'mvd',
    'transactions',
    'chats',
    'blog',
    'thanks',
    'tax',
    'clients',
    'result',
] as const;

export { blocks, blocksOrder };

export type { BlockT };
