import I from '../types.ts';

const setCurrentBlock: I['setCurrentBlock'] = function (currentBlock) {
    if (currentBlock === 'prev') {
        const blockIndex = this.blocksOrder.indexOf(this.state.currentBlock);
        const prevBlock = this.blocksOrder[blockIndex - 1];

        if (!prevBlock) {
            return;
        }

        currentBlock = prevBlock;
    }

    if (currentBlock === 'next') {
        const blockIndex = this.blocksOrder.indexOf(this.state.currentBlock);
        const nextBlock = this.blocksOrder[blockIndex + 1];

        if (!nextBlock) {
            return;
        }

        currentBlock = nextBlock;
    }

    this.setState(
        { currentBlock, ...(currentBlock === 'result' ? { wasResult: true } : {}) },
        this.progressHandler.bind(this),
    );
};

export default setCurrentBlock;
