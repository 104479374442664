import React from 'react';
import { connect } from 'react-redux';

import InfoBlock from '@components/infoBlock/InfoBlock.tsx';
import Items from '@components/items/Items.tsx';
import List from '@components/list/List.tsx';
import ListScroll from '@components/listScroll/ListScroll.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';
import { CustomListenerT, StoreT } from '@global/types.ts';

import changeCorporation from './methods/changeCorporation.ts';
import getItems from './methods/getItems.ts';
import getLocalFilterName from './methods/getLocalFilterName.ts';
import getScrollParent from './methods/getScrollParent.ts';
import setLocalFilter from './methods/setLocalFilter.ts';
import socketHandler from './methods/socketHandler.ts';

import NotificationsI from './types.ts';

import renderActionsHead from './renders/renderActionsHead.tsx';
import renderItem from './renders/renderItem.tsx';

class Notifications
    extends Items<NotificationsI['props'], NotificationsI['state']>
    implements NotificationsI
{
    parent: NotificationsI['parent'];

    constructor(props: NotificationsI['props']) {
        super(props);
        this.state = {
            items: [],
        };

        this.socketHandler = this.socketHandler.bind(this);
        this.changeCorporation = this.changeCorporation.bind(this);

        this.parent = React.createRef();
    }

    classNameItem = '.v2notificationBar__pageItem';
    stepCounter = 15;

    getScrollParent = getScrollParent;
    getItems = getItems;
    socketHandler = socketHandler;
    changeCorporation = changeCorporation;
    setLocalFilter = setLocalFilter;
    getLocalFilterName = getLocalFilterName;

    renderItem = renderItem;
    renderActionsHead = renderActionsHead;

    async componentDidMount() {
        await this.setLocalFilter();

        this.startGetItems.call(this);

        (document.addEventListener as CustomListenerT)('getSocketData', this.socketHandler);
        (document.addEventListener as CustomListenerT)('changeCorporation', this.changeCorporation);
    }

    componentWillUnmount(): void {
        (document.removeEventListener as CustomListenerT)('getSocketData', this.socketHandler);
        (document.removeEventListener as CustomListenerT)(
            'changeCorporation',
            this.changeCorporation,
        );
    }

    render() {
        const {
            items = [],
            isLimit,
            isDisabledScroll,
            updatedListKey,
            isReady,
            isShowMoreLoader,
            counter,
            counterUpdate,
            isInit,
        } = this.state;
        const resultItems = [...items]
            .sort((a, b) => b.updatedDateTime! - a.updatedDateTime!)
            .sort((a, b) => b.sortKey - a.sortKey);
        const isLoading = this.checkLoading();

        return (
            <div
                ref={this.parent}
                className={`v2notificationBar__pageInner _withHead ${isReady && !isLoading ? '_ready' : ''}`}
            >
                <LoaderBlock
                    isShow={!isReady || isLoading}
                    className="v2notificationBar__pageLoader"
                    loaderClassName="_main"
                />
                <LoaderBlock
                    isShow={!!isShowMoreLoader}
                    className="v2notificationBar__pageLoader"
                    loaderClassName="_main"
                    isScroll={true}
                />
                <InfoBlock
                    isShow={!!isReady && !isLoading && counter === 0 && items.length === 0}
                    className="v2notificationBar__pageInfo"
                    title="На данный момент<br/>уведомлений нет"
                    description="Они будут отображаться<br/>здесь в виде карточек"
                />
                {this.renderActionsHead()}
                <div className="v2notificationBar__pageScroll _NOSCROLL">
                    <div className="v2notificationBar__pageWrapper" key={counterUpdate}>
                        <ListScroll
                            getParent={this.getScrollParent.bind(this)}
                            callback={this.getMoreItems.bind(this)}
                            startCounter={this.stepCounter}
                            stepCounter={this.stepCounter}
                            maxCounter={Infinity}
                            lengthCurrent={items.length}
                            handlerLoaderList={this.handlerLoaderList.bind(this)}
                            isLimit={!!isLimit}
                            isDisabledScroll={!!isDisabledScroll || !isInit}
                        >
                            <List
                                renderKey={resultItems.map((item) => item._id).join('')}
                                updateKey={updatedListKey?.toString()}
                                items={resultItems}
                                parentClass="v2notificationBar__pageItems"
                                itemClass="v2notificationBar__pageItem"
                                itemStyleProps={['top']}
                                parentStyleProps={['width']}
                                renderItem={this.renderItem.bind(this)}
                                callback={() => {
                                    if (!this.state.isInit) {
                                        this.setState({ isInit: true });
                                    }
                                }}
                            />
                        </ListScroll>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        isHideAudioNotification: state.isHideAudioNotification,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Notifications);
