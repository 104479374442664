import ArticleT from '@global/models/Article.ts';
import getListItems from '@requests/getListItems.ts';

import I from '../types.ts';

const getArticle: I['getArticle'] = async function () {
    const { id } = this.props;

    try {
        const { item } = await getListItems<ArticleT>({
            url: 'article',
            query: [
                { key: 'id', value: id },
                { key: 'type', value: 'announce' },
                { key: 'system', value: 'crm' },
            ],
        });

        this.setState({ article: item }, () => {
            this.timerId = setTimeout(async () => {
                await this.setView();
            }, 2_000);
        });
    } catch (error) {}
};

export default getArticle;
