import LazyPageI from '../types.ts';

import loadCrm from './load/crm.ts';
import loadCrmContent from './load/crmContent.ts';
import loadCrmJoins from './load/crmJoins.ts';
import loadCrmManual from './load/crmManual.ts';
import loadCrmPays from './load/crmPays.ts';
import loadCrmPublic from './load/crmPublic.ts';
import loadCrmRecruit from './load/crmRecruit.ts';
import loadCrmSettings from './load/crmSettings.ts';
import loadCrmTasks from './load/crmTasks.ts';

const loadComponent: LazyPageI['loadComponent'] = async function (name) {
    let Component;

    if (!Component) {
        Component = await loadCrm(name);
    }

    if (!Component) {
        Component = await loadCrmPublic(name);
    }

    if (!Component) {
        Component = await loadCrmContent(name);
    }

    if (!Component) {
        Component = await loadCrmManual(name);
    }

    if (!Component) {
        Component = await loadCrmJoins(name);
    }

    if (!Component) {
        Component = await loadCrmPays(name);
    }

    if (!Component) {
        Component = await loadCrmSettings(name);
    }

    if (!Component) {
        Component = await loadCrmRecruit(name);
    }

    if (!Component) {
        Component = await loadCrmTasks(name);
    }

    return Component;
};

export default loadComponent;
