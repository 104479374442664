import React from 'react';

import { RenderBlockT } from '../../types.ts';

const mvd: RenderBlockT = function () {
    return (
        <>
            <div className="newYearPopup__stats _COL">
                <div className="newYearPopup__statsCount">
                    <img
                        src={require('../../../../../img/crm/newYear/stats-mvd.svg').default}
                        alt=""
                        className="newYearPopup__statsIcon"
                    />
                    <span></span>
                </div>
                <div className="newYearPopup__statsDescription">
                    <b>уведомлений МВД</b>
                    <br />
                    было подано за год
                </div>
            </div>
        </>
    );
};

export default mvd;
