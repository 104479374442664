import shortText from '@functions/shortText';

import { NotificationInfoT } from '../../types';

const newMessage: NotificationInfoT = {
    subTitle: 'Чаты',
    title: 'Поступило новое сообщение',
    description() {
        const { notification } = this.props;
        const { chatMessage } = notification;

        return `Сообщение: ${shortText({ str: chatMessage!, stop: 20, type: 'word' })}`;
    },
    buttons() {
        const { notification } = this.props;

        return [
            {
                className: '_blue',
                content: 'Ответить',
                pageName: 'chat-inner',
                pageIds: { 3: notification.chatId! },
            },
        ];
    },
};

export default newMessage;
