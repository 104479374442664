import React from 'react';

import checkChange from './methods/checkChange.ts';
import drawItems from './methods/drawItems.ts';
import updateItems from './methods/updateItems.ts';

import ListI from './types.ts';

import renderItem from './renders/renderItem.tsx';

class List extends React.Component<ListI['props'], ListI['state']> implements ListI {
    parent: ListI['parent'];

    constructor(props: ListI['props']) {
        super(props);
        this.state = {
            items: [],
        };

        this.parent = React.createRef();
    }

    states = {};
    indexes = {};
    timers = {};

    renderItem = renderItem;

    updateItems = updateItems;
    drawItems = drawItems;

    checkChange = checkChange;

    componentDidMount(): void {
        this.checkChange();
    }

    componentDidUpdate(): void {
        this.checkChange();
    }

    render() {
        const { items } = this.state;
        const { parentClass } = this.props;

        return (
            <div ref={this.parent} className={`list ${parentClass}`}>
                {items.map((item) => this.renderItem({ item }))}
            </div>
        );
    }
}

export default List;
