import LazyPageI from '@classes/lazyPage/types.ts';

const loadCrmManual: LazyPageI['loadComponent'] = async function (name) {
    let Component;

    if (name === 'manual-executors') {
        Component = (await import('../../../../views/crm/manual/components/executors/Executors')).default;
    }

    if (name === 'manual-docs') {
        Component = (await import('../../../../views/crm/manual/components/docs/Docs')).default;
    }

    if (
        ['manual-tags', 'manual-cities', 'manual-countries', 'manual-executorTypes'].includes(name)
    ) {
        Component = (await import('../../../../components/crm/modelsPage/ModelsPage')).default;
    }

    return Component;
};

export default loadCrmManual;
