import React from 'react';

import I from '../types.ts';

const renderPage: I['renderPage'] = function ({ prop }) {
    const page = this.pages[prop as keyof typeof this.pages];

    return <div className="v2notificationBar__page _ITEM">
        {page.render.call(this)}
    </div>;
};

export default renderPage;
