import React from 'react';
import { connect } from 'react-redux';

import NewYearSnow from '@components/newYearSnow/NewYearSnow.tsx';
import handlerPopup from '@functions/handlerPopup.ts';
import { StoreT } from '@global/types.ts';

import progressHandler from './methods/progressHandler.ts';
import setCurrentBlock from './methods/setCurrentBlock.ts';

import NewYearI from './types.ts';

import renderBlocks from './renders/renderBlocks.tsx';
import renderDecor from './renders/renderDecor.tsx';
import { blocks, blocksOrder } from './static/blocks.tsx';

class NewYear extends React.Component<NewYearI['props'], NewYearI['state']> implements NewYearI {
    parent: NewYearI['parent'];
    navStepAnimateId: NewYearI['navStepAnimateId'];
    countAnimateId: NewYearI['countAnimateId'];

    constructor(props: NewYearI['props']) {
        super(props);
        this.state = {
            currentBlock: 'start',
        };

        this.parent = React.createRef();
    }

    blocks = blocks;
    blocksOrder = blocksOrder;
    resultDecors = [1, 2, 3, 4, 5, 6];

    renderDecor = renderDecor;
    renderBlocks = renderBlocks;

    setCurrentBlock = setCurrentBlock;
    progressHandler = progressHandler;

    componentWillUnmount(): void {
        if (this.navStepAnimateId) {
            cancelAnimationFrame(this.navStepAnimateId);
        }

        if (this.countAnimateId) {
            cancelAnimationFrame(this.countAnimateId);
        }
    }

    render() {
        const { currentBlock } = this.state;
        const { user } = this.props;

        if (!user?.corporations.find((item) => item.id === 'admin')) {
            return null;
        }

        return (
            <div ref={this.parent} className={`newYearPopup _${currentBlock}`}>
                <NewYearSnow speed={1} count={200} white={true} />

                <img
                    src={require('../../../img/crm/newYear/logo-white.svg').default}
                    alt=""
                    className="newYearPopup__logo"
                />
                {this.renderDecor()}
                {this.renderBlocks()}
                <div
                    className="newYearPopup__close _CLICK"
                    onClick={() => {
                        handlerPopup('newYearPopup', { isShow: false });
                        localStorage.setItem('showNewYear', 'true');
                    }}
                >
                    <div className="newYearPopup__closeItem"></div>
                    <div className="newYearPopup__closeItem"></div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(NewYear);
