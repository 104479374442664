import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';

import I from '../types.ts';

const setView: I['setView'] = async function () {
    const { id } = this.props;

    try {
        await axios.post(
            `${process.env.REACT_APP_API}/article`,
            { setView: true, articleId: id },
            { headers: getHeaders() },
        );
    } catch (error) {
        return;
    }
};

export default setView;
