import React from 'react';

import AnnounceContent from '../components/announceContent/AnnounceContent';
import Announces from '../components/announces/Announces';
import Notifications from '../components/notifications/Notifications';

import I from '../types';

const pages = {
    notifications: {
        title: 'Уведомления',
        render(this: I) {
            const { setNotificationsQuery } = this.props;

            return (
                <>
                    <Notifications setNotificationsQuery={setNotificationsQuery} />
                </>
            );
        },
    },
    announces: {
        title: 'Новости',
        render(this: I) {
            return (
                <>
                    <Announces showAnnounce={this.showAnnounce.bind(this)} />
                </>
            );
        },
    },
    announce: {
        title: 'Новости',
        render(this: I) {
            const { announceId } = this.state;

            return (
                <>
                    <AnnounceContent id={announceId!} setPage={this.setPage.bind(this)} />
                </>
            );
        },
    },
} as const;

export default pages;
