import I from '../types.ts';

const getParentSize: I['getParentSize'] = function () {
    const parent = this.parent.current;

    if (parent) {
        return {
            width: parent.offsetWidth,
            height: parent.offsetHeight,
        };
    }

    return {
        width: 0,
        height: 0,
    };
};

export default getParentSize;
