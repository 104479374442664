import I from '../types.ts';

const hideStateHandler: I['hideStateHandler'] = function (
    this: I,
    showHideBar = !this.state.showHideBar,
) {
    this.setState({ showHideBar });
};

export default hideStateHandler;
