import React from 'react';
import { connect } from 'react-redux';

import Animate from '@components/animate/Animate.tsx';
import { StoreT } from '@global/types.ts';

import NewYearBorderI from './types.ts';

class NewYearBorder
    extends React.Component<NewYearBorderI['props'], NewYearBorderI['state']>
    implements NewYearBorderI
{
    parent: NewYearBorderI['parent'];

    constructor(props: NewYearBorderI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { radius, designMode } = this.props;

        return (
            <Animate
                className="newYearBorder"
                style={{ borderRadius: radius }}
                isShow={designMode === 'newYear'}
            >
                <svg className="newYearBorder__inner">
                    <rect
                        className="newYearBorder__rect"
                        x=".75"
                        y=".75"
                        fill="none"
                        rx={radius.includes('%') ? radius : `${+radius - 0.8}px`}
                        ry={radius.includes('%') ? radius : `${+radius - 0.8}px`}
                    />
                </svg>
            </Animate>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        designMode: state.designMode,
    };
}

export default connect(mapStateToProps)(NewYearBorder);
