import React from 'react';

import WidgetField from '@components/crm/widgetField/WidgetField';

import AlertI from '../../types';

const listDealGroup = {
    title: 'Воронка не выбрана',
    description: `Выберите текущую воронку из списка ниже`,
    buttonText: 'Выбрать',
    prevCancel(this: AlertI) {
        const { model, loadingKey, error } = this.state;

        return (
            <>
                <div className="v2popup__field _bottom">
                    <WidgetField
                        model={model}
                        className="_bigSize"
                        name="groupId"
                        prop="groupId"
                        type="select"
                        inputSupport="Воронка"
                        onChange={async (resultData) => {
                            await this.change(resultData);
                        }}
                        value={model?.groupId}
                        iserror={error?.name === 'groupId'}
                        cardName="groupId"
                        disabled={loadingKey === 'save'}
                        selectList="dealGroups"
                        selectProps={{ name: 'groupName' }}
                        selectText={this.getValue({ key: 'groupName' })?.value || ''}
                    />
                </div>
            </>
        );
    },
} as const;

export default listDealGroup;
