import setAsyncState from '@functions/setAsyncState.ts';

import I from '../types.ts';

const socketHandler: I['socketHandler'] = async function ({
    detail: {
        name,
        data: { id, action, notification },
    },
}) {
    const { filterQuery = [] } = this.state;
    const { user } = this.props;

    if (name === 'notifications') {
        if (action === 'delete') {
            await this.deleteItem({ id });
        } else if (
            notification &&
            (notification.corporationId === null ||
                notification.corporationId === user?.idOfCurrentCorporation)
        ) {
            let canAdd = true;

            if (filterQuery.find((item) => item.name === 'types')) {
                const types = filterQuery
                    .filter((item) => item.name === 'types')
                    .map((item) => item.value);

                if (!types.includes(notification!.group)) {
                    canAdd = false;
                }
            }

            if (canAdd) {
                await this.addItem({ item: notification! });
            }
        }

        await setAsyncState.call(this, { updatedListKey: new Date().getTime() });
    }
};

export default socketHandler;
