import setAsyncState from '@functions/setAsyncState.ts';

import I from '../types.ts';

const setNotificationsQuery: I['setNotificationsQuery'] = async function (
    this: I,
    notificationsQuery,
) {
    await setAsyncState.call(this, { notificationsQuery });

    await this.getCounters();
};

export default setNotificationsQuery;
