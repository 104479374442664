import setAsyncState from '@functions/setAsyncState.ts';
import setAsyncTimer from '@functions/setAsyncTimer.ts';

import I, { ItemT } from '../types.ts';

const updateItems: I['updateItems'] = async function ({ isUpdate }) {
    const { items: propsItems, callback } = this.props;
    const { items: stateItems } = this.state;

    const deletesIds: string[] = [];
    const addesIds: string[] = [];
    let isChangeOrder = false;

    const propsItemsIndexes: Record<string, { index: number; item: ItemT }> = {};
    const stateItemsIndexes: Record<string, { index: number; item: ItemT }> = {};

    propsItems.forEach((propsItem, index) => {
        propsItemsIndexes[propsItem._id] = { item: propsItem, index };

        this.indexes[propsItem._id] = index;

        if (this.states[propsItem._id] === 0) {
            isChangeOrder = true;
        }
    });

    stateItems.forEach((stateItem, index) => {
        stateItemsIndexes[stateItem._id] = { item: stateItem, index };

        if (!propsItemsIndexes[stateItem._id]) {
            deletesIds.push(stateItem._id);

            this.states[stateItem._id] = 0;
        } else {
            this.states[stateItem._id] = 1;

            if (propsItemsIndexes[stateItem._id].index !== index) {
                isChangeOrder = true;
            }
        }
    });

    propsItems.forEach((propsItem) => {
        if (!stateItemsIndexes[propsItem._id]) {
            this.states[propsItem._id] = 1;

            addesIds.push(propsItem._id);
        }
    });

    if (!isChangeOrder && !addesIds.length && !deletesIds.length && !isUpdate) {
        return;
    }

    const resultItems = [
        ...stateItems.map((item) => {
            if (isUpdate) {
                const propsItem = propsItemsIndexes[item._id]?.item || item;

                return { ...structuredClone(propsItem) };
            }

            return { ...item };
        }),
    ];

    addesIds.forEach((id) => {
        const { item } = propsItemsIndexes[id];

        resultItems.push({ ...item });
    });

    await setAsyncState.call(this, { items: resultItems });

    await setAsyncTimer(10);

    if (callback) {
        callback();
    }

    await this.drawItems({ addesIds, deletesIds });
};

export default updateItems;
