import React from 'react';

import NewYearCursor from '@components/newYearCursor/NewYearCursor.tsx';
import NewYearSnow from '@components/newYearSnow/NewYearSnow.tsx';

import I from '../types.ts';

const renderNewYear: I['renderNewYear'] = function () {
    const { designMode } = this.props;

    if (designMode !== 'newYear') {
        return null;
    }

    return (
        <>
            <NewYearSnow count={150} speed={1.2} />
            <NewYearCursor />
        </>
    );
};

export default renderNewYear;
