const dealStepSupport = {
    title: 'Подсказка',
    description: `Введите подсказку в поле ниже. Она будет отображаться в колонке на канбан-панели`,
    buttonText: 'Сохранить',
    textMessage: {
        support: 'Подсказка',
    },
} as const;

export default dealStepSupport;
