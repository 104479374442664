import removeTransition from '@functions/removeTransition.ts';
import { dispatcher } from '@redux/redux.ts';

import I from '../types.ts';

const checkHide: I['checkHide'] = function () {
    const { isHideNotificationsBar } = this.props;
    const breakWidth = 1420;

    if (isHideNotificationsBar && window.widthValue > breakWidth) {
        dispatcher<'crm'>({ type: 'isHideNotificationsBar', data: false });

        removeTransition({ item: '.body__notificationsBar' });
    }

    if (!isHideNotificationsBar && window.widthValue <= breakWidth) {
        dispatcher<'crm'>({ type: 'isHideNotificationsBar', data: true });

        removeTransition({ item: '.body__notificationsBar' });
    }
};

export default checkHide;
