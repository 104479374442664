import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';

import I from '../types.ts';

const renderHead: I['renderHead'] = function () {
    const { currentPage } = this.state;
    const { notificationsCounter, announcesCounter } = this.props;

    return (
        <div className="v2notificationBar__head">
            <div className="v2notificationBar__headTabs _ROW">
                {(['notifications', 'announces'] as (keyof typeof this.pages)[]).map((key) => {
                    const page = this.pages[key];
                    let isCurrent = currentPage === key;
                    let counter = 0;

                    if (key === 'announces' && currentPage === 'announce') {
                        isCurrent = true;
                    }

                    if (key === 'notifications') {
                        counter = notificationsCounter;
                    }

                    if (key === 'announces') {
                        counter = announcesCounter;
                    }

                    return (
                        <div
                            className={`v2notificationBar__headTab _COL ${isCurrent ? '_current' : ''}`}
                            key={key}
                        >
                            <div
                                className="v2notificationBar__headTabInner _CLICK"
                                onClick={() => {
                                    this.setPage(key);
                                }}
                            >
                                <b className="v2notificationBar__headTabContent">
                                    {page.title}&nbsp;
                                </b>
                                <AnimateChange
                                    className={`v2notificationBar__headTabContentCounter ${counter > 0 ? '_active' : ''}`}
                                    renderKey={counter}
                                >
                                    <>({counter})</>
                                </AnimateChange>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default renderHead;
