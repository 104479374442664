import React from 'react';
import { connect } from 'react-redux';

import { StoreT } from '@global/types.ts';

import createFlake from './methods/createFlake.ts';
import getParentSize from './methods/getParentSize.ts';
import init from './methods/init.ts';
import loop from './methods/loop.ts';
import randomBetween from './methods/randomBetween.ts';
import resize from './methods/resize.ts';
import scaleCanvas from './methods/scaleCanvas.ts';
import updateFlake from './methods/updateFlake.ts';

import NewYearSnowI from './types.ts';

class NewYearSnow
    extends React.Component<NewYearSnowI['props'], NewYearSnowI['state']>
    implements NewYearSnowI
{
    parent: NewYearSnowI['parent'];
    canvas: NewYearSnowI['canvas'];
    animateId: NewYearSnowI['animateId'];

    constructor(props: NewYearSnowI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
        this.canvas = React.createRef();

        this.resize = this.resize.bind(this);
    }

    flakes = [];

    randomBetween = randomBetween;
    createFlake = createFlake;
    updateFlake = updateFlake;
    scaleCanvas = scaleCanvas;
    loop = loop;
    resize = resize;
    getParentSize = getParentSize;

    init = init;

    componentDidMount(): void {
        this.init();

        window.addEventListener('resize', this.resize);
    }

    componentWillUnmount(): void {
        if (this.animateId) {
            cancelAnimationFrame(this.animateId);
        }

        window.removeEventListener('resize', this.resize);
    }

    render() {
        return (
            <div ref={this.parent} className="newYearSnow">
                <canvas ref={this.canvas} />
            </div>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        designMode: state.designMode,
    };
}

export default connect(mapStateToProps)(NewYearSnow);
