import axios from 'axios';

import getHeaders from '@functions/getHeaders';
import { ReqResponseT } from '@global/types';
import { store } from '@redux/redux';

type ParamsT = {
    taskId: string;
};

export default async function startTask({ taskId }: ParamsT): Promise<void> {
    const user = store.getState().user;

    if (!user) {
        return Promise.reject();
    }

    const response = await axios.patch<{}, { data: ReqResponseT<{}> }>(
        `${process.env.REACT_APP_API}/task`,
        { id: taskId, fields: { status: 'process' } },
        { headers: getHeaders() },
    );
    const { success } = response.data;

    if (success) {
        return;
    }

    return Promise.reject();
}
