import React from 'react';

import { RenderBlockT } from '../../types.ts';

const clients: RenderBlockT = function () {
    return (
        <>
            <div className="newYearPopup__stats _COL">
                <div className="newYearPopup__statsCount">
                    <span></span>
                </div>
                <div className="newYearPopup__statsDescription">
                    <b>новых клиентов</b> выплатили
                    <br />
                    через нашу CRM более
                </div>
                <div className="newYearPopup__statsSubCount">4 000 000 ₽</div>
            </div>
        </>
    );
};

export default clients;
