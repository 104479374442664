import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import setSpacesInText from '@functions/setSpacesInText.ts';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { announce, showAnnounce } = this.props;

    return (
        <>
            <div
                className="v2notification__title"
                dangerouslySetInnerHTML={{ __html: setSpacesInText(announce.title) }}
            ></div>
            <div
                className="v2notification__link _CLICK"
                onClick={() => {
                    showAnnounce(announce._id);
                }}
            >
                {announce.sortKey === 0 ? 'Читать' : 'Читать снова'}
                <i className="v2notification__linkIcon">
                    <Icon name="arrow-next-2" />
                </i>
            </div>
            <div className="v2notification__info _ROW">
                <div className="v2notification__view">
                    {announce.viewCounter || 0}{' '}
                    <i className="v2notification__viewIcon">
                        <Icon name="blog-view" />
                    </i>
                </div>
            </div>
        </>
    );
};

export default renderContent;
