import LazyPageI from '@classes/lazyPage/types.ts';

const loadCrmTasks: LazyPageI['loadComponent'] = async function () {
    let Component;

    // if (name === 'tasks-main') {
    //     Component = (await import('../../../../views/crm/tasks/components/main/Main')).default;
    // }

    // if (name === 'tasks-inner') {
    //     Component = (await import('../../../../views/crm/tasks/components/inner/Inner')).default;
    // }

    return Component;
};

export default loadCrmTasks;
